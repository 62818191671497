import React, { useState, useEffect } from "react";
import "./Checkout.scss";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import "primereact/resources/themes/md-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeicons/primeicons.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { imageURL } from "../../redux/Api";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import {
  OrderDetailAction,
  SendOrderAction,
} from "../../redux/actions/ProductAction";
import { unwrapResult } from "@reduxjs/toolkit";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import "swiper/css/pagination";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  Thumbs,
  Virtual,
} from "swiper/modules";
import { CheckoutNavBar } from "./checkoutNavBar/CheckoutNavBar";
import axios from "axios";

export const Checkout = ({ language }) => {
  const [cartItems, setCartItems] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [currency, setCurrency] = useState("");
  const [errors, setErrors] = useState({});
  const [coupon, setCoupon] = useState("");
  const [couponError, setCouponError] = useState("");
  const [discount, setDiscount] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [subtotal, setSubtotal] = useState(0);

  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem("cartItems")) || [];
    setCartItems(storedCartItems);

    const calculatedSubtotal = storedCartItems.reduce(
      (acc, item) => acc + item.price * item.quantity,
      0
    );

    setSubtotal(calculatedSubtotal);

    window.scrollTo(0, 0);
  }, []);

  const handleCurrencyChange = (e) => {
    setCurrency(e.target.value);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!firstName.trim()) newErrors.firstName = "First name is required";
    if (!lastName.trim()) newErrors.lastName = "Last name is required";
    if (!email.trim()) newErrors.email = "Email is required";
    if (!phone.trim()) newErrors.phone = "Phone number is required";
    if (!currency.trim()) newErrors.currency = "Currency is required";
    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const validateCoupon = async () => {
    try {
      const response = await axios.post(`${OrderDetailAction}${coupon}`);
      const data = await response.data;
      if (data.valid) {
        return data.discount;
      } else {
        throw new Error("Coupon not valid");
      }
    } catch (error) {
      throw new Error("Error validating coupon");
    }
  };

  const handleApplyCoupon = () => {
    if (!phone.trim() || !currency.trim()) {
      setCouponError("Phone number and currency must be selected.");
      return;
    }

    if (coupon !== "") {
      const products = JSON.parse(localStorage.getItem("cartItems")) || [];
      let obj = new FormData();
      obj.append("currency", currency);
      const formattedPhone = `+${phone.replace(/\s/g, "")}`;
      obj.append("phone_number", formattedPhone);
      if (coupon) {
        obj.append("name_coupon", coupon);
      }

      products.forEach((product, i) => {
        obj.append(`orders[${i}][product_id]`, product.id);
        obj.append(`orders[${i}][count]`, product.quantity);
      });

      dispatch(OrderDetailAction(obj))
        .then(unwrapResult)
        .then((res) => {
          if (res.data[0].order[0].discount_percentage) {
            setDiscount(res.data[0].order[0].discount_percentage);
            setCouponError("");
          } else {
            setCouponError("Coupon not valid");
            setDiscount(0);
          }
        })
        .catch(() => {
          setCouponError("Coupon is not valid");
          setDiscount(0);
        });
    } else {
      setCouponError("Coupon cannot be empty");
    }
  };

  const handleMakeOrder = async (e) => {
    e.preventDefault();
    const products = JSON.parse(localStorage.getItem("cartItems")) || [];
    if (validateForm()) {
      const processOrder = () => {
        let obj = new FormData();
        obj.append("currency", currency);
        const formattedPhone = `+${phone.replace(/\s/g, "")}`;
        obj.append("phone_number", formattedPhone);
        if (coupon) {
          obj.append("name_coupon", coupon);
        }

        products.forEach((product, i) => {
          obj.append(`orders[${i}][product_id]`, product.id);
          obj.append(`orders[${i}][count]`, product.quantity);
        });

        dispatch(SendOrderAction(obj))
          .then(unwrapResult)
          .then((res) => {
            console.log(res);
            navigate("/");
          })
          .catch((err) => {
            console.error(err);
          });
      };
      processOrder();
    }
  };
  const cont = {
    en: "Continue shopping",
    ar: "اكمل التسوق",
  };
  const Shopping_Cart = {
    en: "Shopping Cart",
    ar: "عربة التسوق",
  };
  const Discount_code = {
    en: "Discount code",
    ar: "كود الخصم",
  };
  const Complete = {
    en: "Complete",
    ar: "اكمال",
  };
  const your_coupons = {
    en: "Apply your coupons here",
    ar: "استخدم كوبوناتك هنا",
  };
  const First_Name = {
    en: "First Name",
    ar: "الاسم الأول",
  };
  const last_Name = {
    en: "Last Name",
    ar: "الاسم الأخير",
  };
  const Email_ = {
    en: "Email",
    ar: "الإيميل",
  };
  const Paying_with = {
    en: "Paying with:",
    ar: "الدفع بـ:",
  };
  const Select_currency = {
    en: {
      select: "Select currency",
      USD: "USD",
      EUR: "EUR",
      AED: "AED",
      Your_cart_subtotal: "Your cart subtotal:",
      Discount_coupons: "Discount through applied coupons:",
      Checkout: "Checkout",
    },
    ar: {
      select: "اختر العملة",
      USD: "دولار أمريكي",
      EUR: "يورو",
      AED: "درهم إماراتي",
      Your_cart_subtotal: "المجموع الفرعي لعربة التسوق الخاصة بك:",
      Discount_coupons: "خصم من خلال الكوبونات المطبقة:",
      Checkout: "الدفع",
    },
  };

  const totalAfterDiscount = subtotal * ((100 - discount) / 100);
  const [showDis, setShowDis] = useState(false);
  return (
    <div className="checkout">
      <div className="container">
        <div className="body">
          <div className="left">
            <div
              className="header"
              onClick={() => {
                navigate(-1);
              }}
            >
              <i className="bx bx-left-arrow-alt bx-fade-right"></i>
              <h2
                style={{
                  fontFamily: language === "ar" && "El Messiri",
                }}
              >
                {cont[language] || cont.en}
              </h2>
            </div>
            <hr />
            <div className="body_left">
              <h2
                style={{
                  fontFamily: language === "ar" && "El Messiri",
                  letterSpacing: language === "ar" && "0",
                }}
              >
                {Shopping_Cart[language] || Shopping_Cart.en}
              </h2>
              <div className="box_cart">
                {cartItems.map((item, index) => {
                  return (
                    <div className="cart">
                      <div className="imgae_cart">
                        <img
                          src={`${imageURL}${item.file.path}`}
                          alt={`${item.title}`}
                        />
                      </div>
                      <div className="conten_cart">
                        <h2
                          style={{
                            fontFamily: language === "ar" && "El Messiri",
                            letterSpacing: language === "ar" && "0",
                          }}
                        >
                          {item.title}
                        </h2>
                        <p>Price : {`${item.price}$ x ${item.quantity}`}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="right">
            {showDis ? (
              <div className="coupons_box">
                <i
                  className="bx bx-x X"
                  onClick={() => {
                    setShowDis(!showDis);
                  }}
                ></i>
                <label
                  className="title"
                  style={{
                    fontFamily: language === "ar" && "El Messiri",
                  }}
                >
                  {Discount_code[language] || Discount_code.en}
                </label>
                <form className="form">
                  <input
                    type="text"
                    style={{
                      fontFamily: language === "ar" && "El Messiri",
                    }}
                    placeholder={your_coupons[language] || your_coupons.en}
                    className="input_field"
                    value={coupon}
                    onChange={(e) => setCoupon(e.target.value)}
                  />
                  <button
                    style={{
                      fontFamily: language === "ar" && "El Messiri",
                      letterSpacing: language === "ar" && "0",
                    }}
                    type="button"
                    onClick={handleApplyCoupon}
                  >
                    {Complete[language] || Complete.en}
                  </button>
                </form>
                {couponError && (
                  <small className="p-error">{couponError}</small>
                )}
              </div>
            ) : (
              <div
                onClick={() => {
                  setShowDis(!showDis);
                }}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
              >
                <i className="bx bxs-discount bx-tada discount"></i>
                <p
                  style={{
                    color: "#e7bb67",
                    marginLeft: "5px",
                    fontFamily: language === "ar" && "El Messiri",
                  }}
                >
                  {Discount_code[language] || Discount_code.en}
                </p>
              </div>
            )}

            <form>
              <div className="checkout-out-container">
                <div className="left-input-container">
                  <div style={{ display: "flex", gap: "10px" }}>
                    <FloatLabel>
                      <label
                        style={{
                          fontFamily: language === "ar" && "El Messiri",
                        }}
                        htmlFor="firstName"
                      >
                        {First_Name[language] || First_Name.en}
                      </label>
                      <InputText
                        id="firstName"
                        style={{
                          fontFamily: language === "ar" && "El Messiri",
                          width: "100%",
                        }}
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                      />
                    </FloatLabel>
                    {errors.firstName && (
                      <small className="p-error">{errors.firstName}</small>
                    )}

                    <FloatLabel>
                      <label
                        style={{
                          fontFamily: language === "ar" && "El Messiri",
                        }}
                        htmlFor="lastName"
                      >
                        {last_Name[language] || last_Name.en}
                      </label>
                      <InputText
                        id="lastName"
                        style={{
                          paddingLeft: "10px",
                          fontFamily: language === "ar" && "El Messiri",
                        }}
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </FloatLabel>
                    {errors.lastName && (
                      <small className="p-error">{errors.lastName}</small>
                    )}
                  </div>
                  <FloatLabel style={{ width: "100%" }}>
                    <label
                      style={{
                        fontFamily: language === "ar" && "El Messiri",
                      }}
                      htmlFor="email"
                    >
                      {Email_[language] || Email_.en}
                    </label>
                    <InputText
                      id="email"
                      value={email}
                      style={{
                        width: "100%",
                        fontFamily: language === "ar" && "El Messiri",
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </FloatLabel>
                  {errors.email && (
                    <small className="p-error">{errors.email}</small>
                  )}

                  <PhoneInput
                    country={"ae"}
                    value={phone}
                    onChange={(phone) => setPhone(phone)}
                    inputStyle={{
                      width: "100%",
                      fontSize: "16px",
                    }}
                    // containerStyle={{ marginBottom: "20px" }}
                    enableSearch={true}
                  />
                  {errors.phone && (
                    <small className="p-error">{errors.phone}</small>
                  )}

                  <div className="currency-select-container">
                    <label
                      style={{
                        fontFamily: language === "ar" && "El Messiri",
                      }}
                      htmlFor="currency"
                    >
                      {Paying_with[language] || Paying_with.en}
                    </label>
                    <select
                      id="currency"
                      value={currency}
                      onChange={handleCurrencyChange}
                      className="currency-select"
                      style={{
                        fontFamily: language === "ar" && "El Messiri",
                      }}
                    >
                      <option value="" disabled>
                        {Select_currency[language].select ||
                          Select_currency.en.select}
                      </option>
                      <option value="USD">
                        {Select_currency[language].USD ||
                          Select_currency.en.USD}
                      </option>
                      <option value="EUR">
                        {Select_currency[language].EUR ||
                          Select_currency.en.EUR}
                      </option>
                      <option value="AED">
                        {Select_currency[language].AED ||
                          Select_currency.en.AED}
                      </option>
                    </select>
                  </div>
                  {errors.currency && (
                    <small className="p-error">{errors.currency}</small>
                  )}
                </div>
              </div>
            </form>
            <div className="checkout_box">
              <div className="details">
                <span
                  style={{
                    fontFamily: language === "ar" && "El Messiri",
                  }}
                >
                  {Select_currency[language].Your_cart_subtotal ||
                    Select_currency.en.Your_cart_subtotal}
                </span>
                <span>{`${subtotal}$`}</span>
                <span
                  style={{
                    fontFamily: language === "ar" && "El Messiri",
                  }}
                >
                  {Select_currency[language].Discount_coupons ||
                    Select_currency.en.Discount_coupons}
                </span>
                <span>{`${discount}%`}</span>
              </div>
              <div className="checkout--footer">
                <label className="price">
                  <sup>{`$`}</sup>
                  {`${totalAfterDiscount.toFixed(2)}`}
                </label>
                <button  style={{
                    fontFamily: language === "ar" && "El Messiri",
                  }} className="checkout-btn" onClick={handleMakeOrder}>
                  {Select_currency[language].Checkout ||
                    Select_currency.en.Checkout}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <CheckoutNavBar />
      <form>
        <div className="checkout-out-container">
          <div className="checkout-left-container">
            <div className="checkout-left-title">Shipping</div>
            <div className="left-input-container">
              <FloatLabel>
                <label htmlFor="firstName">First Name</label>
                <InputText
                  id="firstName"
                  style={{ paddingLeft: "10px" }}
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </FloatLabel>
              {errors.firstName && (
                <small className="p-error">{errors.firstName}</small>
              )}

              <FloatLabel>
                <label htmlFor="lastName">Last Name</label>
                <InputText
                  id="lastName"
                  style={{ paddingLeft: "10px" }}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </FloatLabel>
              {errors.lastName && (
                <small className="p-error">{errors.lastName}</small>
              )}

              <FloatLabel>
                <label htmlFor="email">Email</label>
                <InputText
                  id="email"
                  style={{ paddingLeft: "10px" }}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FloatLabel>
              {errors.email && (
                <small className="p-error">{errors.email}</small>
              )}

              <PhoneInput
                country={"ae"}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                inputStyle={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "16px",
                }}
                containerStyle={{ marginBottom: "20px" }}
                enableSearch={true}
              />
              {errors.phone && (
                <small className="p-error">{errors.phone}</small>
              )}

              <div className="currency-select-container">
                <label htmlFor="currency">Paying with:</label>
                <select
                  id="currency"
                  value={currency}
                  onChange={handleCurrencyChange}
                  className="currency-select"
                >
                  <option value="" disabled>
                    Select currency
                  </option>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                  <option value="AED">AED</option>
                </select>
              </div>
              {errors.currency && (
                <small className="p-error">{errors.currency}</small>
              )}
            </div>
          </div>
          <div className="checkout-right-container">
            <Swiper
              loop={false}
              modules={[
                Autoplay,
                FreeMode,
                Navigation,
                Thumbs,
                Pagination,
                Virtual,
              ]}
              spaceBetween={50}
              slidesPerView={1}
              className="bg-slider"
              pagination={{ clickable: true }}
              thumbs={{
                swiper:
                  thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null,
              }}
            >
              {cartItems.map((item, index) => (
                <SwiperSlide key={index}>
                  <div className="checkout-right-item">
                    <div className="checkout-right-images">
                      <img
                        src={`${imageURL}${item.file.path}`}
                        alt={`${item.title}`}
                      />
                    </div>
                    <div className="checkout-right-details">
                      <div className="checkout-right-details-title">
                        <h2>{item.title}</h2>
                        <p>{`${item.price}$ x ${item.quantity}`}</p>
                      </div>
                      <p className="checkout-right-details-description">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>



            <div className="checkout-right-button">
              <div className="card coupons">
                <label className="title">Apply coupons</label>
                <form className="form">
                  <input
                    type="text"
                    placeholder="Apply your coupons here"
                    className="input_field"
                    value={coupon}
                    onChange={(e) => setCoupon(e.target.value)}
                  />
                  <button type="button" onClick={handleApplyCoupon}>
                    Apply
                  </button>
                </form>
                {couponError && (
                  <small className="p-error">{couponError}</small>
                )}
              </div>

              <div className="card checkout">
                <label className="title">Checkout</label>
                <div className="details">
                  <span>Your cart subtotal:</span>
                  <span>{`${subtotal}$`}</span>
                  <span>Discount through applied coupons:</span>
                  <span>{`${discount}%`}</span>
                </div>
                <div className="checkout--footer">
                  <label className="price">
                    <sup>{`$`}</sup>
                    {`${totalAfterDiscount.toFixed(2)}`}
                  </label>
                  <button className="checkout-btn" onClick={handleMakeOrder}>
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form> */}
    </div>
  );
};
