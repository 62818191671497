export const baseURL =
  "https://back-end.mirruba-jewellery.com/public/index.php/api";
// const BASIC_URL = "http://192.168.43.69:8000/api";
export const imageURL = "https://back-end.mirruba-jewellery.com/public";
// const URL_Image = "http://192.168.43.69:8000";

// Categories
export const getCategories = "/v1/client/category";

// Product
export const getProducts = "/v1/client/product?page=";
export const getProductsByCategory = "/v1/client/product/category/";

// view
export const showProduct = `/v1/client/product/`;

// order
export const sendOrder = "/v1/client/order";
export const OrderDetail = "/v1/client/order/detail";

// filter
export const getFilterByCategory = "/v1/client/product/filter/";
export const getFilterProducts = "/v1/client/product/filter?page=1&size=10&isPaginate=0&"
