import React, {  createContext, useState } from 'react'

export const AuthContext = createContext();

export const AuthProvider = ({children}) =>{

    const [isCartOpen, setCartOpen] = useState(false);

    

    return(
        <AuthContext.Provider value={{ isCartOpen, setCartOpen}}>
            {children}
        </AuthContext.Provider>
    )
}
