import React, { useEffect } from "react";
import { Element } from "react-scroll";
import "./About.scss";
import Aos from "aos";

const About = ({ language }) => {
  const h1 = {
    en: `About`,
    ar: "معلومات عنا",
  };
  const title = {
    en: `An Icon Of Absolute Femininity`,
    ar: "أيقونة من الأنوثة المطلقة",
  };
  const title2 = {
    en: ` It reflects the life of a solid , independent woman.. of rare beauty, An uncontrollable force awakens in you, enchants you, and takes you to a world of irresistible, femininity and attractiveness, succumb to her call; You have no resemblance but your mirror.`,
    ar: "تعكس حياة امرأة صلبة .. مستقلة.. ذات جمالٍ نادرٍ. توقظ فيك قوةً لا يمكن السيطرة عليها.. تسحرك.. وتأخذك لعالم من الأنوثة والجاذبية لا تقاوم, استسلمي لندائها; فليس لكِ شبيه سوى مرآتك .",
  };
  useEffect(() => {
    // Aos.init({ duration: 1000 });
  }, []);
  return (
    <Element name="About">
      <div className="About" id="About">
        <div className="contanier">
          <div className="text_header">
            <h1
              style={{
                fontFamily: language === "ar" && "El Messiri",
                letterSpacing: language === "ar" && "0",
              }}
            >
              {h1[language] || h1.en}
            </h1>
            <p
              style={{
                fontFamily:language === "ar" && "El Messiri",
                letterSpacing:language === "ar" && "0",
              }}
            >
              {title[language] || title.en}
            </p>
          </div>
          <div className="about_body">
            <div className="content" data-aos="fade-up">
              <p>
                <label>{title[language] || title.en}</label>,
                {title2[language] || title2.en}
              </p>
            </div>
            <div className="image" data-aos="fade-down">
              <img
                src={require("../../assets/image/jewellery-girl-welcome-row-1(2).png")}
                alt="image"
              />
            </div>
          </div>
        </div>
      </div>
    </Element>
  );
};

export default About;
