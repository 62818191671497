import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FilterByCategory, GetProductsByCategory } from '../../redux/actions/ProductAction';
import './PriceFilter.scss';
import { FormControl, InputLabel, NativeSelect } from '@mui/material';

const PriceFilter = ({ id, onCurrencyChange , language}) => {
    const [priceRange, setPriceRange] = useState('');
    const [customPrice, setCustomPrice] = useState({ from: '', to: '' });
    const [currency, setCurrency] = useState('USD');
    const dispatch = useDispatch();

    const price = {
        en: {
            under:"Under",
            to: "to",
            above: "Above",
            from: "From",
            
        },
        ar: {
            under:" أقل من",
            to: "الى",
            above: "أكثر من",
            from: "من"
        }
    }

    const currencySymbols = {
        USD: '$',
        EUR: '€',
        AED: 'AED',
    };
    const applyFilter = useCallback((range, from, to, currency) => {
        let info = {
            category: id,
            page: 1,
            size: 10,
            isPaginate: 0,
            price_from: 0,
            price_to: 9999999,
            currency: currency,
        };

        if (range) {
            switch (range) {
                case 'under50':
                    info.price_from = 0;
                    info.price_to = 49;
                    break;
                case '50to100':
                    info.price_from = 50;
                    info.price_to = 99;
                    break;
                case '100to250':
                    info.price_from = 100;
                    info.price_to = 249;
                    break;
                case '250to500':
                    info.price_from = 250;
                    info.price_to = 499;
                    break;
                case '500plus':
                    info.price_from = 500;
                    info.price_to = 9999999;
                    break;
                default:
                    break;
            }
        } else if (from && to) {
            info.price_from = from;
            info.price_to = to;
        }

        dispatch(FilterByCategory(info));
    }, [dispatch, id]);

    const clearFilter = useCallback(() => {
        dispatch(GetProductsByCategory({ category: id, page: 1, size: 10, isPaginate: 1 }));
        setPriceRange('');
        setCustomPrice({ from: '', to: '' });
    }, [dispatch, id]);

    const handleCheckboxChange = (e) => {
        const selectedRange = e.target.value;
        if (priceRange === selectedRange) {
            clearFilter();
        } else {
            setPriceRange(selectedRange);
            setCustomPrice({ from: '', to: '' });
            applyFilter(selectedRange, '', '', currency);
        }
    };

    const handleCustomPriceChange = (e) => {
        const { name, value } = e.target;
        setCustomPrice(prev => ({ ...prev, [name]: value }));
    };

    const handleCustomFilterApply = () => {
        if (customPrice.from !== '' && customPrice.to !== '') {
            applyFilter('', customPrice.from, customPrice.to, currency);
        }
    };

    const handleCurrencyChange = (e) => {
        const newCurrency = e.target.value;
        setCurrency(newCurrency);
        onCurrencyChange(currencySymbols[newCurrency]);
        if (priceRange) {
            applyFilter(priceRange, '', '', newCurrency);
        } else if (customPrice.from !== '' && customPrice.to !== '') {
            applyFilter('', customPrice.from, customPrice.to, newCurrency);
        } else {
            applyFilter('', '', '', newCurrency);
        }
    };

    useEffect(() => {
        if (customPrice.from !== '' || customPrice.to !== '') {
            setPriceRange('');
        }
    }, [customPrice]);

    return (
        <div className="price-filter-container">
            <div className="price-filter-inputs">
                <div className='price-filter-check-boxes'>
                    <div>
                        <input
                            type="radio"
                            id="under50"
                            name="priceRange"
                            value="under50"
                            checked={priceRange === 'under50'}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="under50">{price[language].under } 50{currencySymbols[currency]}</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="50to100"
                            name="priceRange"
                            value="50to100"
                            checked={priceRange === '50to100'}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="50to100">50{currencySymbols[currency]} {price[language].to || price.en.to} 99{currencySymbols[currency]}</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="100to250"
                            name="priceRange"
                            value="100to250"
                            checked={priceRange === '100to250'}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="100to250">100{currencySymbols[currency]} {price[language].to || price.en.to} 249{currencySymbols[currency]}</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="250to500"
                            name="priceRange"
                            value="250to500"
                            checked={priceRange === '250to500'}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="250to500">{`250${currencySymbols[currency]} ${price[language].to || price.en.to} 500${currencySymbols[currency]}`}</label>
                    </div>
                    <div>
                        <input
                            type="radio"
                            id="500plus"
                            name="priceRange"
                            value="500plus"
                            checked={priceRange === '500plus'}
                            onChange={handleCheckboxChange}
                        />
                        <label htmlFor="500plus">{price[language].above || price.en.above} 500{currencySymbols[currency]}</label>
                    </div>
                </div>
                <div className="custom-price-filter">
                    <div className='custom-price-input-first'>
                        <label htmlFor="price_from">{price[language].from || price.en.from}: </label>
                        <input
                            type="number"
                            id="price_from"
                            name="from"
                            value={customPrice.from}
                            onChange={handleCustomPriceChange}
                        />
                    </div>
                    <div className='custom-price-input-second'>
                        <label htmlFor="price_to">{price[language].to || price.en.to}: </label>
                        <input
                            type="number"
                            id="price_to"
                            name="to"
                            value={customPrice.to}
                            onChange={handleCustomPriceChange}
                        />
                    </div>
                    <div className='filter-button'>
                        <button onClick={handleCustomFilterApply}>Filter</button>
                    </div>
                </div>

            </div>
            <FormControl fullWidth className='currency-selector'>
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                    Currency
                </InputLabel>
                <NativeSelect
                className='cureency-options'
                    defaultValue={'USD'}
                    inputProps={{
                        id: 'currency',
                    }}
                    onChange={handleCurrencyChange}
                >
                    <option value='USD'>USD</option>
                    <option value='EUR'>EUR</option>
                    <option value='AED'>AED</option>
                </NativeSelect>
            </FormControl>
        </div>
    );
};

export default PriceFilter;
