import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    items: [],
    selectedItem: null, 
};

const viewSlice = createSlice({
    name: 'view',
    initialState,
    reducers: {
        addItemToCart: (state, action) => {
            state.items.push(action.payload);
        },
        selectItemToView: (state, action) => {
            state.selectedItem = action.payload;
        },
    },
});

export const { addItemToCart , selectItemToView } = viewSlice.actions;

export default viewSlice.reducer;
