import { configureStore } from '@reduxjs/toolkit';
import viewReducer from './slicers/viewSlice';
import cartReducer from './slicers/cartSlice';
import ProductReducer from './reducerrs/ProductReducer';

export const store = configureStore({
  reducer: {
    cart: cartReducer,
    view: viewReducer,
    products: ProductReducer,
  },
});