import React, { useContext, useEffect, useState, useRef } from "react";
import { Link } from "react-scroll";
import "./Header.scss";
import { AuthContext } from "../../context/Context";

const Header = ({ language, onLanguageChange }) => {
  const [scrolled, setScrolled] = useState(false);
  const [showSide, setShowSide] = useState(false);
  const { setCartOpen, isCartOpen } = useContext(AuthContext);
  const navRef = useRef(null);

  const header = {
    en: {
      Home: "Home",
      Shop: "Shop",
      About: "About",
      Earrings: "Earrings",
      Rings: "Rings",
      Necklaces: "Necklaces",
      Bracelets: "Bracelets",
      Contact: "Contact Us",
      English: "English",
      Arabic: "Arabic",
    },
    ar: {
      Home: "الرئيسية",
      Shop: "تسوق",
      About: "معلومات عنا",
      Earrings: "حلق",
      Rings: "خواتم",
      Necklaces: "أطواق",
      Bracelets: "أساور",
      Contact: "تواصل معنا",
      English: "انجليزي",
      Arabic: "عربي",
    },
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (navRef.current && !navRef.current.contains(event.target)) {
        setShowSide(false);
      }
    };

    if (showSide) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showSide]);

  const toggleMenu = () => {
    setShowSide(!showSide);
  };

  const toggleCart = () => {
    setCartOpen(!isCartOpen);
  };

  const handleLanguageChange = (lang) => {
    onLanguageChange(lang);
  };
  const [showMenu, setShowMenu] = useState(false);
  const [activeviwo] = useState("");

  return (
    <header className={`${scrolled ? "scroll" : ""}`}>
      <div className="nav-bar">
        <Link
          to="home"
          spy={true}
          smooth={true}
          offset={0}
          duration={500}
          className="Logo"
        >
          <img
            className="logo-header"
            src={require("../../assets/image/logo-ruba.png")}
            alt="Logo"
            style={{ width: 180 }}
          />
        </Link>

        <div ref={navRef} className={`navigation ${showSide ? "active" : ""}`}>
          <div className="nav-item">
            <div className="nav-item-left">
              <i
                onClick={() => setShowSide(!showSide)}
                className="bx bx-x uil uil-times nav-close-btn"
              />
              <Link
                onClick={() => setShowSide(!showSide)}
                to="home"
                className="nav-link"
                spy={true}
                smooth={true}
                offset={-60}
                duration={800}
                style={{ fontFamily: language === "ar" && "El Messiri" }}
              >
                <i className="" />
                {header[language].Home || header.en}
              </Link>
              <Link
                onClick={() => setShowSide(!showSide)}
                to="About"
                className="nav-link"
                spy={true}
                smooth={true}
                offset={-60}
                duration={800}
                style={{ fontFamily: language === "ar" && "El Messiri" }}
              >
                <i className="" />
                {header[language].About || header.en}
              </Link>
              <Link
                onClick={() => setShowSide(!showSide)}
                to="AllSections"
                className="nav-link"
                spy={true}
                smooth={true}
                offset={-60}
                duration={800}
                style={{ fontFamily: language === "ar" && "El Messiri" }}
              >
                <i className="" />
                {header[language].Shop || header.en}
              </Link>
              {/* <Link
                onClick={() => setShowSide(!showSide)}
                to="earring"
                className="nav-link"
                spy={true}
                smooth={true}
                offset={-60}
                duration={100}
              >
                <i className="" />
                {header[language].Earrings || header.en}
              </Link>
              <Link
                onClick={() => setShowSide(!showSide)}
                to="rings"
                className="nav-link"
                spy={true}
                smooth={true}
                offset={-60}
                duration={100}
              >
                <i className="" />
                {header[language].Rings || header.en}
              </Link>
              <Link
                onClick={() => setShowSide(!showSide)}
                to="necklaces"
                className="nav-link"
                spy={true}
                smooth={true}
                offset={-60}
                duration={100}
              >
                <i className="" />
                {header[language].Necklaces || header.en}
              </Link>
              <Link
                onClick={() => setShowSide(!showSide)}
                to="bracelets"
                className="nav-link"
                spy={true}
                smooth={true}
                offset={-60}
                duration={100}
              >
                <i className="" />
                {header[language].Bracelets || header.en}
              </Link> */}
              <Link
                onClick={() => setShowSide(!showSide)}
                to="Contact"
                className="nav-link"
                spy={true}
                smooth={true}
                offset={-60}
                duration={800}
                style={{ fontFamily: language === "ar" && "El Messiri" }}
              >
                <i className="" />
                {header[language].Contact || header.en}
              </Link>
            </div>

            <div className="language-selector-mobile">
              <button
                style={{ fontFamily: language === "ar" && "El Messiri" }}
                className={`language-btn ${language === "en" ? "active" : ""}`}
                onClick={() => handleLanguageChange("en")}
              >
                {header[language].English || header.en}
              </button>
              <button
                style={{ fontFamily: language === "ar" && "El Messiri" }}
                className={`language-btn ${language === "ar" ? "active" : ""}`}
                onClick={() => handleLanguageChange("ar")}
              >
                {header[language].Arabic || header.en}
              </button>
            </div>
          </div>
        </div>

        <div className="header-cart">
          <Link
            to="Contact"
            className="nav-link"
            spy={true}
            smooth={true}
            offset={-60}
            duration={800}
            style={{ marginLeft: language === "ar" && "20px" }}
          >
            <i
              style={{ fontSize: "25px", marginRight: "20px" }}
              className="bx bxs-map"
            />
          </Link>
          <i className="pi pi-shopping-cart" onClick={toggleCart}></i>
        </div>

        <div
          className="pi pi-align-justify Login-place"
          onClick={toggleMenu}
        ></div>

        <div className="language-selector-desktop">
          <button
            className={`language-btn ${language === "en" ? "active" : ""}`}
            onClick={() => handleLanguageChange("en")}
          >
            English
          </button>
          <button
            className={`language-btn ${language === "ar" ? "active" : ""}`}
            onClick={() => handleLanguageChange("ar")}
          >
            Arabic
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
