import React from "react";

const NotFound = () => {
  return (
    <div style={{ backgroundColor: "#231f20" }}>
      <dotlottie-player
        src="https://lottie.host/686a3ba7-aa4b-47f7-ac7b-66afc90cfe86/hbIrDZ3gKu.json"
        background="transparent"
        speed="1"
        style={{
          width: "100%",
          height: "100vh",
        }}
        loop
        autoplay
      >
        <iframe src="https://lottie.host/embed/686a3ba7-aa4b-47f7-ac7b-66afc90cfe86/hbIrDZ3gKu.json"></iframe>
      </dotlottie-player>
    </div>
  );
};

export default NotFound;
