import React from "react";
import "../bracelets/Bracelets.scss";
import { Section } from "./Section";
import { Element } from "react-scroll";

export const AllSections = ({ language }) => {
  const bracelets = {
    en: "Our Collections",
    ar: "منتجاتنا ",
  };
  return (
    <Element name="AllSections">
      <div className="bracelets-out-container">
        <div className="earring-title">
          <h2 style={{ fontFamily: language === "ar" && "El Messiri" }}>
            {bracelets[language] || bracelets.en}
          </h2>
        </div>
        <Section language={language} />
      </div>
    </Element>
  );
};
