// src/App.js
import React, { useContext, useState } from 'react';
import AppRoutes from './routes/AppRoutes';
import { AuthContext } from './context/Context';
import Cart from './page/cart/Cart';

const App = () => {
  const { setCartOpen, isCartOpen } = useContext(AuthContext);

  const toggleCart = () => {
    setCartOpen(!isCartOpen);
  };

  const [language, setLanguage] = useState('en'); // Default language is English

  const handleLanguageChange = (newLanguage) => {
    setLanguage(newLanguage);
  };

  return (
    <>
      <AppRoutes language={language} handleLanguageChange={handleLanguageChange} />
      <Cart language={language} isOpen={isCartOpen} onClose={toggleCart} />
    </>
  );
};

export default App;
