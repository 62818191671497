import React, { useContext, useEffect, useState } from "react";
import "./AllBracelets.scss";
import { Element } from "react-scroll";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectItemToView } from "../../redux/slicers/viewSlice";
import { AuthContext } from "../../context/Context";
import { GetProductsByCategory } from "../../redux/actions/ProductAction";
import { imageURL } from "../../redux/Api";
import { resetFilters } from "../../redux/reducerrs/ProductReducer";
import { MutatingDots } from "react-loader-spinner";
import PriceFilter from "../price_filter/PriceFilter";
import { useLocation } from "react-router-dom";

export const AllBracelets = ({ language }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setCartOpen, isCartOpen } = useContext(AuthContext);
  const { loading, filteredProducts } = useSelector((state) => state.products);
  const [initialProducts, setInitialProducts] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [currencySymbol, setCurrencySymbol] = useState("$");
  const [isPriceFilterVisible, setIsPriceFilterVisible] = useState(false);
  const location = useLocation();
  const handleCurrencyChange = (symbol) => {
    setCurrencySymbol(symbol);
  };

  const handleCardClick = (id) => {
    dispatch(selectItemToView(id));
    navigate("/view", {
      state: {
        data: id,
      },
    });
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(resetFilters()); // Reset filters on component mount
    const fetchProducts = async () => {
      try {
        const response = await dispatch(
          GetProductsByCategory({
            category: location.state,
            page: 1,
            size: 10,
            isPaginate: 0,
            lang: language,
          })
        );
        const products = response.payload.data;
        setInitialProducts(products);
      } catch (err) {
        console.error(err);
      }
    };
    fetchProducts();
  }, []);

  useEffect(() => {
    if (filteredProducts.length > 0) {
      setIsFilterApplied(true);
    } else {
      setIsFilterApplied(false);
      setInitialProducts("");
    }
  }, [filteredProducts]);

  const toggleCart = () => {
    setCartOpen(!isCartOpen);
  };

  const togglePriceFilter = () => {
    setIsPriceFilterVisible(!isPriceFilterVisible);
  };

  const productsToDisplay = isFilterApplied
    ? filteredProducts
    : initialProducts;

  const NotFound = {
    en: "NotFound",
    ar: "لم يتم العثور على المنتج",
  };
  const All_Products = {
    en: " All Products",
    ar: "كل المنتجات",
  };
  const Show_Price_Filter = {
    en: "Show Price Filter",
    ar: "اظهار البحث",
  };
  const Hide_Price_Filter = {
    en: "Show Price Filter",
    ar: "اخفاء البحث",
  };
  return (
    <Element name="bracelets">
      <div className="allBracelets-out-container">
        <div className="allBracelets-navbar">
          <div
            className="link-allBracelets-left-arrow pi pi-chevron-left"
            onClick={() => navigate(-1)}
          ></div>
          <span
            style={{
              fontFamily: language === "ar" && "El Messiri",
            }}
          >
            {All_Products[language] || All_Products.en}
          </span>
          <div
            className="link-allBracelets pi pi-shopping-cart"
            onClick={toggleCart}
          ></div>
        </div>
        <button
          style={{
            fontFamily: language === "ar" && "El Messiri",
          }}
          className="price-filter-show-btn"
          onClick={togglePriceFilter}
        >
          {isPriceFilterVisible
            ? Hide_Price_Filter[language] || Hide_Price_Filter.en
            : Show_Price_Filter[language] || Show_Price_Filter.en}
        </button>
        {isPriceFilterVisible && (
          <div className="allBracelets-price-filter-container">
            <PriceFilter
              id={location.state}
              onCurrencyChange={handleCurrencyChange}
              language={language}
            />
          </div>
        )}
        {loading ? (
          <div className="allEarring-loading">
            <MutatingDots
              visible={true}
              height="100"
              width="100"
              color="#231f20"
              secondaryColor="#231f20"
              radius="15.5"
              ariaLabel="mutating-dots-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </div>
        ) : (
          <div className="allBracelets-container">
            {productsToDisplay?.length === 0 ? (
              <p
                style={{
                  fontFamily: language === "ar" && "El Messiri",
                }}
                className="allBracelets-paragraph-no-product"
              >
                {NotFound[language] || NotFound.en}
              </p>
            ) : (
              productsToDisplay?.map((bracelet) => (
                <div
                  key={bracelet.id}
                  className="allBracelets-card"
                  onClick={() => handleCardClick(`${bracelet.id}`)}
                >
                  <img
                    src={`${imageURL}${bracelet.file.path}`}
                    alt={`Bracelets ${bracelet.id}`}
                  />
                  <div className="allBracelets-details">
                    <p
                      style={{
                        fontFamily: language === "ar" && "El Messiri",
                        letterSpacing: language === "ar" && "0",
                      }}
                      className="title"
                    >
                      {bracelet.title}
                    </p>
                    <p
                      style={{
                        fontFamily: language === "ar" && "El Messiri",
                        letterSpacing: language === "ar" && "0",
                      }}
                      className="description"
                    >
                      {bracelet.description}
                    </p>
                    <p
                      style={{
                        fontFamily: language === "ar" && "El Messiri",
                        letterSpacing: language === "ar" && "0",
                      }}
                      className="price"
                    >{`${bracelet.price}${currencySymbol}`}</p>
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </Element>
  );
};
