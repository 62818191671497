import React from "react";
import "./CheckoutNavBar.scss";
import "primeicons/primeicons.css";
import { Link, useNavigate } from "react-router-dom";

export const CheckoutNavBar = () => {
  const navigate = useNavigate();

  return (
    <div className="allBracelets-navbar">
      <div
        className="pi pi-arrow-circle-left"
        onClick={() => navigate(-1)}
      ></div>
      <Link to="/">
        <img
          src={require("../../../assets/image/LogoAlaaeditedd.png")}
          alt=""
        />
      </Link>
    </div>
  );
};
