import React, { useContext, useEffect, useState } from 'react';
import { Element } from 'react-scroll';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import './AllRings.scss'; // Import the styles
import { AuthContext } from '../../context/Context';
import { GetProductsByCategory } from '../../redux/actions/ProductAction';
import { imageURL } from '../../redux/Api';
import { MutatingDots } from 'react-loader-spinner';
import { resetFilters } from '../../redux/reducerrs/ProductReducer';
import PriceFilter from '../price_filter/PriceFilter';
import { selectItemToView } from '../../redux/slicers/viewSlice';

export const AllRings = ({ language }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setCartOpen, isCartOpen } = useContext(AuthContext);
    const { loading, filteredProducts } = useSelector((state) => state.products);
    const [initialProducts, setInitialProducts] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [isPriceFilterVisible, setIsPriceFilterVisible] = useState(false);

    const handleCurrencyChange = (symbol) => {
        setCurrencySymbol(symbol);
    };

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to top when the view component is rendered
        dispatch(resetFilters('rings')); // Reset filters for rings

        const fetchProducts = async () => {
            try {
                const response = await dispatch(GetProductsByCategory({ category: 3, page: 1, size: 10, isPaginate: 1, lang: language }));
                const products = response.payload.data.data;
                setInitialProducts(products);
            } catch (err) {
                console.error(err);
            }
        };
        fetchProducts();
    }, [dispatch]);

    useEffect(() => {
        if (filteredProducts.length > 0) {
            setIsFilterApplied(true);
        } else {
            setIsFilterApplied(false);
            setInitialProducts('')
        }
    }, [filteredProducts]);

    const handleCardClick = (id) => {
        dispatch(selectItemToView(id));
        navigate('/view', {
            state: {
                data: id
            }
        });
    };

    const toggleCart = () => {
        setCartOpen(!isCartOpen);
    };

    const togglePriceFilter = () => {
        setIsPriceFilterVisible(!isPriceFilterVisible);
    };

    const productsToDisplay = isFilterApplied ? filteredProducts : initialProducts;

    return (
        <Element name='ring'>
            <div className='allRings-out-container'>
                <div className='allRings-navbar'>
                    <div className='link-allRings-left-arrow pi pi-chevron-left' onClick={() => navigate(-1)}></div>
                    <span>All Rings</span>
                    <div className='link-allRings pi pi-shopping-cart' onClick={toggleCart}></div>
                </div>
                <button className='price-filter-show-btn' onClick={togglePriceFilter}>
                    {isPriceFilterVisible ? 'Hide Price Filter' : 'Show Price Filter'}
                </button>
                {isPriceFilterVisible && (
                    <div className={`price-filter-container ${language === 'ar' ? 'rtl' : 'ltr'}`}>
                        <PriceFilter id={3} onCurrencyChange={handleCurrencyChange} language={language} />
                    </div>
                )}
                {loading ? (
                    <div className='allRings-loading'>
                        <MutatingDots
                            visible={true}
                            height="100"
                            width="100"
                            color="#231f20"
                            secondaryColor="#231f20"
                            radius="15.5"
                            ariaLabel="mutating-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                ) : (
                    <div className="allRings-container">
                        {productsToDisplay.length === 0 ? (
                            <p className='allRings-paragraph-no-product'>No products found</p>
                        ) : (
                            productsToDisplay.map((ring) => (
                                <div key={ring.id} className="allRings-card" onClick={() => handleCardClick(`${ring.id}`)}>
                                    <img src={`${imageURL}${ring.file.path}`} alt={`Ring ${ring.id}`} />
                                    <div className='allRings-details-container'>
                                        <div className="allRings-left-details">
                                            <p className="title">{ring.title}</p>
                                            <p className="description">{ring.description}</p>
                                            <p className="price">{`${ring.price}${currencySymbol}`}</p>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                )}
                <div className='allRings-footer'>
                    <span>Mirruba Copy Right 2024</span>
                </div>
            </div>
        </Element>
    );
};
