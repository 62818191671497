import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./View.scss";
import { BackFromViewBtn } from "../../component/uiverse/BackFromView/BackFromViewBtn";
import { useNavigate, Link, useLocation } from "react-router-dom";
import AddToCartButton from "../../component/uiverse/AddToCart/AddToCart";
import { AuthContext } from "../../context/Context";
import "primeicons/primeicons.css";
import { imageURL } from "../../redux/Api";
import { ShowProductAction } from "../../redux/actions/ProductAction";
import { ColorPicker } from "primereact/colorpicker";
import { MutatingDots } from "react-loader-spinner";
import { Swiper, SwiperSlide } from "swiper/react";
import { Image } from "primereact/image";

import {
  Autoplay,
  FreeMode,
  Navigation,
  Pagination,
  Thumbs,
  Virtual,
} from "swiper/modules";

const View = ({ language }) => {
  const selectedItem = useSelector((state) => state.view.selectedItem);
  const { loading } = useSelector((state) => state.products);
  const navigate = useNavigate();
  const [product, setProduct] = useState([]);
  const [pics, setPics] = useState([]);
  const dispatch = useDispatch();
  const location = useLocation();
  const { setCartOpen, isCartOpen } = useContext(AuthContext);

  const view = {
    en: "Color",
    ar: "اللون",
  };

  useEffect(() => {
    let info = { id: location?.state?.data, lang: language };
    dispatch(ShowProductAction(info))
      .then((res) => {
        setProduct(res.payload.data.product);
        localStorage.setItem("products", res.payload.data.product);
        console.log(res.payload.data);
        setPics(res.payload.data.files);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dispatch, location?.state?.data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const toggleCart = () => {
    setCartOpen(!isCartOpen);
  };

  // console.log(pics);

  if (!selectedItem) {
    return (
      <div className="no-item-to-view-container">
        <div className="no-item-to-view">
          No item selected to view.
          <Link className="no-item-to-view-back-btn" to={navigate(-1)}>
            Go Back{" "}
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="view-out-container">
      <div className="view-navbar">
        <div className="back-from-view-container">
          <BackFromViewBtn language={language} />
        </div>
        <Link to="/">
          <img
            src={require("../../assets/image/LogoAlaaEdited.png")}
            alt="Logo"
          />
        </Link>
        <div
          className="view-cart-btn pi pi-shopping-cart"
          onClick={toggleCart}
        ></div>
      </div>
      {loading ? (
        <div className="view-loading">
          <MutatingDots
            visible={true}
            height="100"
            width="100"
            color="#e7bb67"
            secondaryColor="#e7bb67"
            radius="15.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="view-container">
          <div className="view-images">
            <div className="left">
              {pics.map((img, index) => (
                <Image
                  preview
                  key={index}
                  src={`${imageURL}${img.path}`}
                  alt="pic"
                  className="view-img-of-swiper-details"
                />
              ))}
            </div>
            <div className="right">
              {product?.file?.path && (
                <Image
                  preview
                  src={`${imageURL}${product.file.path}`}
                  alt="pic"
                  className="view-img-of-swiper"
                />
              )}
            </div>
            {/* <Swiper
              loop={false}
              modules={[
                Autoplay,
                FreeMode,
                Navigation,
                Thumbs,
                Pagination,
                Virtual,
              ]}
              spaceBetween={50}
              slidesPerView={1}
              className="bg-slider-in-view"
              pagination={{ clickable: true }}
            >
              {product?.file?.path && (
                <SwiperSlide>
                  <div>
                    <h2>{product.file.path}</h2>
                    <img
                      src={`${imageURL}${product.file.path}`}
                      alt="pic"
                      className="view-img-of-swiper"
                    />
                  </div>
                </SwiperSlide>
              )}

              {pics.map((img, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={`${imageURL}${img.path}`}
                    alt="pic"
                    className="view-img-of-swiper-details"
                  />
                </SwiperSlide>
              ))}
            </Swiper> */}
          </div>

          <div className="view-details-container">
            <div className="view-details-title">
              <h2
                style={{
                  fontFamily: language === "ar" && "El Messiri",
                  letterSpacing: language === "ar" && "0",
                }}
              >
                {product?.title} <p>{`${product?.price}$`}</p>
              </h2>
              <p
                style={{ fontFamily: language === "ar" && "El Messiri" }}
                className="view-details-description"
              >
                {product?.description}
              </p>
              <div className="view-details-color-container">
                <div
                  style={{
                    marginRight: "10px",
                    fontFamily: language === "ar" && "El Messiri",
                  }}
                >
                  {view[language] || view.en}:
                </div>
                <div className="view-details-colors">
                  <ColorPicker
                    className="color"
                    value={product.color}
                    disabled={true}
                  />
                </div>
              </div>
            </div>

            <div className="add-to-bag-button">
              <AddToCartButton language={language} item={product} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default View;
