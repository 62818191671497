import React from "react";
import "./BraceletsMoreBtn.scss";
import { Link, NavLink } from "react-router-dom";

export const BraceletsMoreBtn = ({ idCaT, language }) => {
  const more = {
    en: "See More",
    ar: "تصفح المزيد",
  };
  return (
    <div class="bracelets-more-btn-container">
      <div class="bracelets-btn">
        <NavLink
          to={"/allProducts"}
          style={{ fontFamily: language === "ar" && "El Messiri" }}
          state={idCaT}
        >
          {more[language] || more.en}
        </NavLink>
      </div>
    </div>
  );
};
