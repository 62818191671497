import { createAsyncThunk } from '@reduxjs/toolkit'
import { getCategories , getFilterByCategory, getFilterProducts, getProducts, getProductsByCategory, OrderDetail, sendOrder, showProduct } from '../Api';
import axiosInstance, { errorMessage } from '../constant';

export const GetProductsAction = createAsyncThunk("products/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(getProducts + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate + '&currency=' + info.currency);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const GetProductsByCategory = createAsyncThunk("products-by-category/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(getProductsByCategory + info.category + "?page=" + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate , {headers:{'Accept-Language':info.lang}});
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});
export const GetCategory = createAsyncThunk("products-by-category/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(getCategories + "?page=" + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate , {headers:{'Accept-Language':info.lang}});
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const ShowProductAction = createAsyncThunk("products/show", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(showProduct + info.id , {headers:{'Accept-Language':info.lang}});
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const SendOrderAction = createAsyncThunk("send/order", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(sendOrder , info );
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const OrderDetailAction = createAsyncThunk("products/show", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.post(OrderDetail , info );
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const FilterByCategory = createAsyncThunk("filtered-products-by-category/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(getFilterByCategory + info.category + "?page=" + info.page + "&size=" + info.size + "&isPaginate=" + info.isPaginate + '&price_from=' + info.price_from + '&price_to=' + info.price_to + '&currency=' + info.currency);
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});

export const FilterProducts = createAsyncThunk("filtered-products/get", async (info, { rejectWithValue }) => {
    try {
        let { data } = await axiosInstance.get(getFilterProducts + 'price_from=' + info.price_from + "&price_to=" + info.price_to + "&currency=" + info.currency );
        return data;
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data.message);
        } else {
            return rejectWithValue(errorMessage);
        }
    }
});