// src/LayOut.js
import React from "react";
import { Home } from "./page/home/Home";
import Rings from "./page/rings/Rings";
import Header from "./component/Header/Header";
import { Necklaces } from "./page/necklaces/Necklaces";
import { Trending } from "./page/trending/Trending";
import { Bracelets } from "./page/bracelets/Bracelets";
import { Contact } from "./page/contact/Contact";
import { Earring } from "./page/earrings/Earring";
import Categories from "./page/categories/Categories";
import Logos from "./component/Logos/Logos";
import { AllSections } from "./page/AllSections/AllSections";
import About from "./page/About/About";

export const LayOut = ({ language, handleLanguageChange }) => {
  return (
    <>
      <div className={`header-container ${language === "ar" ? "rtl" : "ltr"}`}>
        <Header language={language} onLanguageChange={handleLanguageChange} />
      </div>
      <Home language={language} />
      {/* <div className={`category-container ${language === 'ar' ? 'rtl' : 'ltr'}`}> */}
      {/* <Categories /> */}
      {/* </div> */}
      <About language={language} />
      <AllSections language={language} />
      {/* <div
        className={`trending-container ${language === "ar" ? "rtl" : "ltr"}`}
      >
        <Trending language={language} />
      </div> */}
      <Logos />
      {/* <Earring language={language} /> */}
      {/* <Logos /> */}
      {/* <Rings language={language} /> */}
      {/* <Logos /> */}
      {/* <Necklaces language={language} /> */}
      {/* <Logos /> */}
      {/* <Bracelets language={language} /> */}
      {/* <Logos /> */}
      <div className={`contact-container ${language === "ar" ? "rtl" : "ltr"}`}>
        <Contact language={language} />
      </div>
    </>
  );
};
