import axios from "axios";
import { baseURL } from "./Api";
import { useNavigate } from "react-router-dom";

export const errorMessage = "An unexpected error occurred !!";
const axiosInstance = axios.create({
  baseURL: baseURL,
  // timeout: 10000,
});
// axiosInstance.defaults.headers.common['Accept-Language'] = 'ar'; // Replace 'en-US' with your desired language
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const nav = useNavigate();
    if (error.response) {
      const { status } = error.response;
      switch (status) {
        case 400:
          console.error("Bad Request");
          break;
        case 401:
          console.error("Unauthorized");
          window.location.href = "/";
          break;
        case 403:
          console.error("Forbidden");
          break;
        case 404:
          console.error("Not Found");
          nav("/website_closed")
          break;
        case 500:
          console.error("Internal Server Error");
          break;
        default:
          console.error("An unexpected error occurred");
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
