import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { addItemToCart } from "../../../redux/slicers/cartSlice";
import "./AddToCart.scss";

const AddToCartButton = ({ language, item }) => {
  const dispatch = useDispatch();
  const [isAdded, setIsAdded] = useState(false);
  const btn = {
    en: "Add To Cart",
    ar: "اضافة الى السلة",
  };
  const handleAddToCart = () => {
    // Dispatch the action to add item to Redux store
    dispatch(addItemToCart(item));

    // Get current cart items from localStorage
    let cartItems = JSON.parse(localStorage.getItem("cartItems")) || [];

    // Check if the item is already in the cart
    const isItemInCart = cartItems.some((cartItem) => cartItem.id === item.id);

    // If item is not in the cart, add it
    if (!isItemInCart) {
      cartItems.push(item);
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }

    // Set added state for feedback
    setIsAdded(true);
    setTimeout(() => {
      // setIsAdded(false);
    }, 2000); // Adjust duration as needed
  };

  return (
    <>
      {!isAdded ? (
        <button
          style={{
            fontFamily: language === "ar" && "El Messiri",
          }}
          onClick={handleAddToCart}
          className={`add-to-cart-button ${isAdded ? "added" : ""}`}
        >
          {isAdded ? "Added!" : btn[language] || btn.en}
        </button>
      ) : (
        <button
          style={{
            fontFamily: language === "ar" && "El Messiri",
          }}
          // onClick={handleAddToCart}
          disabled
          className={`add-to-cart-button ${isAdded ? "added" : ""}`}
        >
          {isAdded ? "Added!" : btn[language] || btn.en}
        </button>
      )}
    </>
  );
};

export default AddToCartButton;
