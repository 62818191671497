import React from "react";
import "./Logos.scss";

const Logos = () => {
  return (
    <div className="Logos">
      <div className="Logos_slide">
        <img src={require("../../assets/image/logo-ruba.png")} alt="logo" />
        <img src={require("../../assets/image/logo-ruba.png")} alt="logo" />
        <img src={require("../../assets/image/logo-ruba.png")} alt="logo" />
        <img src={require("../../assets/image/logo-ruba.png")} alt="logo" />
        <img src={require("../../assets/image/logo-ruba.png")} alt="logo" />
        <img src={require("../../assets/image/logo-ruba.png")} alt="logo" />
        <img src={require("../../assets/image/logo-ruba.png")} alt="logo" />
        <img src={require("../../assets/image/logo-ruba.png")} alt="logo" />
        <img src={require("../../assets/image/logo-ruba.png")} alt="logo" />
        <img src={require("../../assets/image/logo-ruba.png")} alt="logo" />
        <img src={require("../../assets/image/logo-ruba.png")} alt="logo" />
        <img src={require("../../assets/image/logo-ruba.png")} alt="logo" />
      </div>
    </div>
  );
};

export default Logos;
