import React, { useContext, useEffect, useState } from 'react';
import './AllEarrings.scss';
import { Element } from 'react-scroll';
import { useNavigate } from 'react-router-dom';
import { selectItemToView } from '../../redux/slicers/viewSlice';
import { useDispatch, useSelector } from 'react-redux';
import { AuthContext } from '../../context/Context';
import { GetProductsByCategory } from '../../redux/actions/ProductAction';
import { imageURL } from '../../redux/Api';
import { MutatingDots } from 'react-loader-spinner';
import PriceFilter from '../price_filter/PriceFilter';
import { resetFilters } from '../../redux/reducerrs/ProductReducer'; // Import resetFilters

export const AllEarrings = ({ language }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, filteredProducts } = useSelector((state) => state.products);
    const { setCartOpen, isCartOpen } = useContext(AuthContext);
    const [initialProducts, setInitialProducts] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [isPriceFilterVisible, setIsPriceFilterVisible] = useState(false);


    const handleCurrencyChange = (symbol) => {
        setCurrencySymbol(symbol);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(resetFilters()); // Reset filters on component mount
        const fetchProducts = async () => {
            try {
                const response = await dispatch(GetProductsByCategory({ category: 2, page: 1, size: 10, isPaginate: 1, lang: language }));
                const products = response.payload.data.data;
                setInitialProducts(products);
            } catch (err) {
                console.error(err);
            }
        };
        fetchProducts();
    }, []);

    // Update filter state based on changes in filteredProducts
    useEffect(() => {
        if (filteredProducts.length > 0) {
            setIsFilterApplied(true);
        } else {
            setIsFilterApplied(false);
            setInitialProducts('')
        }
    }, [filteredProducts]);

    const handleCardClick = (id) => {
        dispatch(selectItemToView(id));
        navigate('/view', {
            state: {
                data: id
            }
        });
    };

    const toggleCart = () => {
        setCartOpen(!isCartOpen);
    };

    const togglePriceFilter = () => {
        setIsPriceFilterVisible(!isPriceFilterVisible);
    };

    const productsToDisplay = isFilterApplied ? filteredProducts : initialProducts


    console.log(filteredProducts)


    return (
        <Element name='earring'>
            <div className='allEarrings-out-container'>
                <div className='allEarrings-navbar'>
                    <div className='link-allEarring-left-arrow pi pi-chevron-left' onClick={() => navigate(-1)}></div>
                    <span>All Earrings</span>
                    <div className='link-allEarring pi pi-shopping-cart' onClick={toggleCart}></div>
                </div>
                <button className='price-filter-show-btn' onClick={togglePriceFilter}>
                    {isPriceFilterVisible ? 'Hide Price Filter' : 'Show Price Filter'}
                </button>
                {isPriceFilterVisible && (
                    <div className='allEarrings-price-filter-container'>
                        <PriceFilter id={2} onCurrencyChange={handleCurrencyChange} language={language} />
                    </div>)}
                {loading ? (
                    <div className='allEarring-loading'>
                        <MutatingDots
                            visible={true}
                            height="100"
                            width="100"
                            color="#231f20"
                            secondaryColor="#231f20"
                            radius="15.5"
                            ariaLabel="mutating-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                ) : (
                    <div className="allEarring-section">
                        <div className="allEarring-container">
                            {productsToDisplay.length === 0 ? (
                                <p className='allEarring-paragraph-no-product'>No products found</p>
                            ) : (
                                productsToDisplay.map((earring) => (
                                    <div key={earring.id} onClick={() => handleCardClick(`${earring.id}`)} className="allEarring-card">
                                        <img src={`${imageURL}${earring.file.path}`} alt={`earring ${earring.id}`} />
                                        <div className="allEarring-details">
                                            <p className="title">{earring.title}</p>
                                            <p className="description">{earring.description}</p>
                                            <p className="price">{`${earring.price}${currencySymbol}`}</p>
                                        </div>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                )}
                <div className='allEarring-footer'>
                    <span>Mirruba Copy Right 2024</span>
                </div>
            </div>
        </Element>
    );
};
