import React from "react";
import "./Contact.scss";
import { Element } from "react-scroll";
import TextField from "@mui/material/TextField";
import "primeicons/primeicons.css";
import { SocialBtns } from "../../component/socialMedia/SocialBtns";
import { InputTextarea } from "primereact/inputtextarea";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";

export const Contact = ({ language }) => {
  const contactHandleSubmit = (e) => {
    e.preventDefault();
  };

  const contactSection = {
    en: {
      name: "Name",
      email: "Email",
      description: "Description",
      btn: "Send",
      location: "Sharjah, Emirates, Central Market",
      details: "Mirruba Jewelry since 2024",
      h1: "Contact Us",
      p: `Ready to turn your ideas into reality? Let's talk! I'm here to
              listen, guide, and help you bring your vision to life.`,
    },
    ar: {
      name: "الاسم",
      email: "الايميل",
      description: "الوصف",
      btn: "ارسال",
      location: "الشارقة , الامارات , السوق المركزي",
      details: " مجوهرات mirruba منذ 2024",
      h1: "تواصل معنا",
      p: `هل أنت مستعد لتحويل أفكارك إلى واقع؟ دعنا نتحدث! أنا هنا للاستماع إليك وتوجيهك ومساعدتك في تحقيق رؤيتك.`,
    },
  };

  return (
    <Element name="Contact">
      <div id="Contact" className="Contact">
        <div className="contanier">
          <div className="text_header">
            <h1
              style={{
                fontFamily: language === "ar" && "El Messiri",
                letterSpacing: language === "ar" && "0",
              }}
            >
              {contactSection[language].h1 || contactSection.en.h1}
            </h1>
            <p
              style={{
                fontFamily: language === "ar" && "El Messiri",
                letterSpacing: "1px",
                maxWidth: "75%",
                margin: "auto",
              }}
            >
              {contactSection[language].p || contactSection.en.p}
            </p>
          </div>
          <div className="Contact_body">
            <div className="social_madia">
              <ul>
                <li>
                  <i className="bx bxs-map"></i>
                  <p>
                    {contactSection[language].location ||
                      contactSection.en.location}
                  </p>
                </li>
                <li>
                  <i className="bx bxs-phone"></i>
                  <p>+971 501 045 496</p>
                </li>
                <li>
                  <i className="bx bxl-gmail"></i>
                  <p>mirrubaofficial@gmail.com</p>
                </li>
              </ul>
              <div className="Icon">
                <ul className="wrapper">
                  <a
                    href="https://www.facebook.com/share/pwy3V98o1Qe2sdQd/?mibextid=LQQJ4d"
                    target="_blank"
                  >
                    <li className="icon facebook">
                      <span className="tooltip">Facebook</span>
                      <svg
                        viewBox="0 0 320 512"
                        height="1.2em"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                      </svg>
                    </li>
                  </a>
                  <a
                    href="https://www.instagram.com/mirrubaofficial?igsh=ZjhzaHQ2cTBmdDJ5"
                    target="_blank"
                  >
                    <li className="icon instagram">
                      <span className="tooltip">Instagram</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="1.2em"
                        fill="currentColor"
                        className="bi bi-instagram"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"></path>
                      </svg>
                    </li>
                  </a>
                </ul>
              </div>
            </div>
            <div className="form">
              <form onSubmit={contactHandleSubmit}>
                <div className="card flex justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="username"
                      //   value={value}
                      //   onChange={(e) => setValue(e.target.value)}
                      style={{ fontFamily: language === "ar" && "El Messiri" }}
                    />
                    <label
                      htmlFor="username"
                      style={{ fontFamily: language === "ar" && "El Messiri" }}
                    >
                      {contactSection[language].name || contactSection.en.name}
                    </label>
                  </FloatLabel>
                </div>
                <div className="card flex justify-content-center">
                  <FloatLabel>
                    <InputText
                      id="email"
                      //   value={value}
                      //   onChange={(e) => setValue(e.target.value)}
                      style={{ fontFamily: language === "ar" && "El Messiri" }}
                    />
                    <label
                      htmlFor="email"
                      style={{ fontFamily: language === "ar" && "El Messiri" }}
                    >
                      {contactSection[language].email ||
                        contactSection.en.email}
                    </label>
                  </FloatLabel>
                </div>
                <div className="card flex justify-content-center">
                  <FloatLabel>
                    <InputTextarea
                      autoResize
                      id="description"
                      //   rows={6}
                      //   value={value}
                      //   onChange={(e) => setValue(e.target.value)}
                      style={{ fontFamily: language === "ar" && "El Messiri" }}
                    />
                    <label
                      htmlFor="description"
                      style={{ fontFamily: language === "ar" && "El Messiri" }}
                    >
                      {contactSection[language].description ||
                        contactSection.en.description}
                    </label>
                  </FloatLabel>
                </div>
                <button
                  class="contact-button"
                  style={{ fontFamily: language === "ar" && "El Messiri" }}
                >
                  {contactSection[language].btn || contactSection.en.btn}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="contact-out-container">
        <div className="contact-top-container">
          <div className="contact-left-side">
            <img
              src={require("../../assets/image/LogoAlaaEdited.png")}
              alt=""
            />
          </div>
          <div className="contact-right-side">
            <form className="contact-form" onSubmit={contactHandleSubmit}>
              <TextField
                className="text-field"
                id="outlined-basic"
                label={contactSection[language].name || contactSection.en.name}
                variant="outlined"
                type="text"
                required
              />
              <TextField
                className="text-field"
                id="outlined-basic"
                label={
                  contactSection[language].email || contactSection.en.email
                }
                variant="outlined"
                type="email"
                required
              />
              <TextField
                className="text-field last-text-field"
                id="outlined-multiline-flexible"
                label={
                  contactSection[language].description ||
                  contactSection.en.description
                }
                multiline
                maxRows={9}
                required
              />
              <button class="contact-button">
                {contactSection[language].btn || contactSection.en.btn}
              </button>
            </form>
          </div>
        </div>
        <div className="contact-bottom-container">
          <SocialBtns />
          <div className="contact-location">
            <i className="pi pi-map-marker"></i>
            <p>
              {contactSection[language].location || contactSection.en.location}
            </p>
          </div>
          <div className="contact-phone">
            <i className="pi pi-phone"></i>
            <p>+971 501 045 496</p>
          </div>
          <p className="contact-details">
            {contactSection[language].details || contactSection.en.details}
          </p>
        </div>
      </div> */}
    </Element>
  );
};
