import React, { useContext, useEffect, useState } from 'react';
import './AllNecklaces.scss';
import { Element } from 'react-scroll';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectItemToView } from '../../redux/slicers/viewSlice';
import { AuthContext } from '../../context/Context';
import { GetProductsByCategory } from '../../redux/actions/ProductAction';
import { imageURL } from '../../redux/Api';
import { ColorPicker } from 'primereact/colorpicker';
import { MutatingDots } from 'react-loader-spinner';
import PriceFilter from '../price_filter/PriceFilter';
import { resetFilters } from '../../redux/reducerrs/ProductReducer';

export const AllNecklaces = ({ language }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { loading, filteredProducts } = useSelector((state) => state.products);
    const { setCartOpen, isCartOpen } = useContext(AuthContext);
    const [visibleDescriptions, setVisibleDescriptions] = useState({});
    const [initialProducts, setInitialProducts] = useState([]);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const [currencySymbol, setCurrencySymbol] = useState('$');
    const [isPriceFilterVisible, setIsPriceFilterVisible] = useState(false);


    const handleCurrencyChange = (symbol) => {
        setCurrencySymbol(symbol);
    };

    const handleCardClick = (id) => {
        dispatch(selectItemToView(id));
        navigate('/view', {
            state: {
                data: id
            }
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(resetFilters()); // Reset filters on component mount
        const fetchProducts = async () => {
            try {
                const response = await dispatch(GetProductsByCategory({ category: 4, page: 1, size: 10, isPaginate: 1, lang: language }));
                const products = response.payload.data.data;
                setInitialProducts(products);
            } catch (err) {
                console.error(err);
            }
        };
        fetchProducts();
    }, []);

    useEffect(() => {
        if (filteredProducts.length > 0) {
            setIsFilterApplied(true);
        } else {
            setIsFilterApplied(false);
            setInitialProducts('')
        }
    }, [filteredProducts]);

    const toggleCart = () => {
        setCartOpen(!isCartOpen);
    };

    const toggleDescriptionVisibility = (id) => {
        setVisibleDescriptions(prevState => ({
            ...prevState,
            [id]: !prevState[id]
        }));
    };

    const truncateDescription = (description, limit = 15) => {
        if (description.length <= limit) return description;
        return `${description.slice(0, limit)}...`;
    };

    const togglePriceFilter = () => {
        setIsPriceFilterVisible(!isPriceFilterVisible);
    };

    const productsToDisplay = isFilterApplied ? filteredProducts : initialProducts


    return (
        <Element name='necklaces'>
            <div className='allNecklaces-out-container'>
                <div className='allNecklaces-navbar'>
                    <div className='link-allNecklaces-left-arrow pi pi-chevron-left' onClick={() => navigate(-1)}></div>
                    <span>All Necklaces</span>
                    <div className='link-allNecklaces-shopping-cart pi pi-shopping-cart' onClick={toggleCart}></div>
                </div>
                <button className='price-filter-show-btn' onClick={togglePriceFilter}>
                    {isPriceFilterVisible ? 'Hide Price Filter' : 'Show Price Filter'}
                </button>
                {isPriceFilterVisible && (
                    <div className='allNecklaces-price-filter-container'>
                        <PriceFilter id={4} onCurrencyChange={handleCurrencyChange} language={language} />
                    </div>)}
                {loading ? (
                    <div className='allEarring-loading'>
                        <MutatingDots
                            visible={true}
                            height="100"
                            width="100"
                            color="#231f20"
                            secondaryColor="#231f20"
                            radius="15.5"
                            ariaLabel="mutating-dots-loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                        />
                    </div>
                ) : (
                    <div className="allNecklaces-card-container">
                        {productsToDisplay.length === 0 ? (
                            <p className='allNecklaces-paragraph-no-product'>No products found</p>
                        ) : (
                            productsToDisplay.map((necklace) => {
                                const isVisible = visibleDescriptions[necklace.id];
                                return (
                                    <div key={necklace.id} className="allNecklaces-card" onClick={() => handleCardClick(necklace.id)}>
                                        <img src={`${imageURL}${necklace.file.path}`} alt={`necklace ${necklace.id}`} />
                                        <div className="allNecklaces-card-details">
                                            <p className="title">{necklace.title}</p>
                                            <p className="price">{`${necklace.price}${currencySymbol}`}</p>
                                            <p className="description">
                                                {isVisible ? necklace.description : truncateDescription(necklace.description)}
                                                {necklace.description.length > 15 && (
                                                    <span className="show-more" onClick={(e) => {
                                                        e.stopPropagation();
                                                        toggleDescriptionVisibility(necklace.id);
                                                    }}>
                                                        {isVisible ? 'Show Less' : 'Show More'}
                                                    </span>
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                );
                            })
                        )}
                    </div>
                )}
                <div className='allNecklaces-footer'>
                    <span>Mirruba Copy Right 2024</span>
                </div>
            </div>
        </Element>
    );
};
