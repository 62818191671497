import { createSlice } from '@reduxjs/toolkit';
import { FilterByCategory, GetProductsAction, GetProductsByCategory, ShowProductAction } from '../actions/ProductAction';

export const productReducer = createSlice({
    name: "productReducer",
    initialState: {
        loading: false,
        totalItems: "",
        currentPage: "",
        products: [],
        productInfo: [],
        filteredProducts: [
], // Manage filtered products
    },
    reducers: {
        productUnmount: (state) => {
            state.productInfo = [];
        },
        resetFilters: (state) => { // Action to reset filters
            state.filteredProducts = []; // Reset filtered products
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(GetProductsAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetProductsAction.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.products = payload.data?.data ?? payload.data;
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetProductsAction.rejected, (state) => {
                state.loading = false;
            })

            .addCase(ShowProductAction.pending, (state) => {
                state.loading = true;
            })
            .addCase(ShowProductAction.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.productInfo = payload.data;
            })
            .addCase(ShowProductAction.rejected, (state) => {
                state.loading = false;
            })

            .addCase(GetProductsByCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(GetProductsByCategory.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.products = payload.data?.data ?? payload.data; // Update the products array
                state.totalItems = payload.data.total;
                state.currentPage = payload.data.current_page;
            })
            .addCase(GetProductsByCategory.rejected, (state) => {
                state.loading = false;
            })

            .addCase(FilterByCategory.pending, (state) => {
                state.loading = true;
            })
            .addCase(FilterByCategory.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.filteredProducts = payload.data?.data ?? payload.data; // Handle filtered products
            })
            .addCase(FilterByCategory.rejected, (state) => {
                state.loading = false;
            });
    }
});

export default productReducer.reducer;
export const { productUnmount, resetFilters } = productReducer.actions;
