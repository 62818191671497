import React, { useEffect } from "react";
import "./Home.scss";
import { Element, Link } from "react-scroll";
import { DarkMoreBtn } from "../../component/uiverse/DarkMoreBtn/DarkMoreBtn";
import hoverEffects from "hover-effect";

export const Home = ({ language }) => {
  useEffect(() => {
    var hoverEffect = new hoverEffects({
      parent: document.querySelector(".home .item-mimage"),
      intensity: 0.3,
      image1: require("../../assets/image/model1.jpg"),
      image2: require("../../assets/image/photo_5877552136739472531_y.jpg"),
      displacementImage: require("../../assets/image/photo_5877552136739472532_y.jpg"),
    });
  }, []);
  const btn = {
    // en: `AN ICON OF ABSOLUTE FEMININTY `,
    en: `Shop Now`,
    // ar: "رمز الأنوثة المطلقة",
    ar: "تسوق الآن",
  };
  const p = {
    // en: `AN ICON OF ABSOLUTE FEMININTY `,
    en: `We are committed to providing a unique shopping experience, Through our website, you will be able to find the perfect jewelry pieces that suit your style and preferences, whether you are searching for a special gift or looking to add a touch of luxury to your jewelry collection.`,
    // ar: "رمز الأنوثة المطلقة",
    ar: "نحن ملتزمون بتقديم تجربة تسوق فريدة من نوعها، حيث يمكنكم استكشاف تشكيلة متنوعة من المجوهرات التي تتميز بالجودة العالية والتصميم الراقي. من خلال موقعنا، ستتمكنون من العثور على المجوهرات التي تناسب أذواقكم وتفضيلاتكم، سواء كنت تبحث عن هدية خاصة أو تريد إضافة لمسة من الفخامة إلى خزانة مجوهراتكم.",
  };
  return (
    <Element name="Home" className="home-out-container">
      <div className="home" id="home">
        <div
          className="home-left-details"
          style={{
            zIndex: "1",
          }}
        >
          <img
            src={require("../../assets/image/LogoAlaaEdited.png")}
            className="home-left-img-logo"
            alt=""
          />
          {/* <h3 className="btn-shine">{title[language] || title.en}</h3> */}
          <p
            style={{
              fontFamily: language === "ar" && "El Messiri",
              letterSpacing: language === "ar" && "0",
            }}
          >
            {p[language] || p.en}
          </p>
          <Link
            spy={true}
            smooth={true}
            offset={-60}
            duration={100}
            to="AllSections"
            style={{ fontFamily: language === "ar" && "El Messiri" }}
          >
            {btn[language] || btn.en}
          </Link>
          {/* <DarkMoreBtn language={language} /> */}
        </div>
        <div
          className="item-image"
          // style={{
          //   position: "absolute",
          //   top: "50%",
          //   transform: "translate(5%,-50%)",
          //   height: "70vh",
          //   width: "550px",
          //   objectFit: "cover",
          //   right: "50px",
          //   borderRadius: "20px",
          //   backgroundColor: "red",
          // }}
        ></div>
        <div className="home-right-details"></div>
      </div>
    </Element>
  );
};
