import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectItemToView } from "../../redux/slicers/viewSlice";
import {
  GetCategory,
  GetProductsByCategory,
} from "../../redux/actions/ProductAction";
import { BraceletsMoreBtn } from "../../component/uiverse/BraceletsMoreBtn/BraceletsMoreBtn";
import { imageURL } from "../../redux/Api";
import { MutatingDots } from "react-loader-spinner";
import { Element } from "react-scroll";

export const Section = ({ language }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [dataPro, setDataPro] = useState([]);
  const { loading } = useSelector((state) => state.products);
  const [valueSelect, setValueSelect] = useState("1");
  useEffect(() => {
    let info = { page: 1, size: "", isPaginate: 0, lang: language };
    dispatch(GetCategory(info))
      .then((res) => {
        setData(res.payload.data);
      })
      .catch((err) => {
        console.error("error saying: " + err);
      });
  }, [dispatch, language]);
  useEffect(() => {
    let info = {
      category: parseInt(valueSelect),
      page: 1,
      size: 4,
      isPaginate: 1,
      lang: language,
    };
    dispatch(GetProductsByCategory(info))
      .then((res) => {
        setDataPro(res.payload.data.data);
        console.log(res);
      })
      .catch((err) => {
        console.error("error saying: " + err);
        navigate("/website_closed")
      });
  }, [dispatch, language, valueSelect]);

  const handleCardClick = (id) => {
    dispatch(selectItemToView(id));
    navigate("/view", {
      state: {
        data: id,
      },
    });
  };
  return (
    <Element>
      <div>
        <select
          style={{
            // backgroundColor: "#e7bb67",
            backgroundColor: "transparent",
            border: "2px solid #e7bb67",
            color: "white",
            padding: "10px 20px",
            fontSize: "20px",
            borderRadius: "10px",
            fontFamily: language === "ar" && "El Messiri",
          }}
          onChange={(e) => {
            setValueSelect(e.target.value);
          }}
          value={valueSelect}
        >
          <option value="" disabled></option>
          {data?.map((Cat, index) => {
            return (
              <option style={{ color: "black" }} key={index} value={Cat.id}>
                {Cat.name}
              </option>
            );
          })}
        </select>
      </div>
      {loading ? (
        <div className="bracelets-loading">
          <MutatingDots
            visible={true}
            height="100"
            width="100"
            color="#e7bb67"
            secondaryColor="#e7bb67"
            radius="15.5"
            ariaLabel="mutating-dots-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      ) : (
        <div className="bracelets-container">
          {dataPro.map((bracelets) => (
            <div
              key={bracelets.id}
              onClick={() => handleCardClick(`${bracelets.id}`)}
              className="bracelets-card"
            >
              <img
                src={`${imageURL}${bracelets.file.path}`}
                alt={`bracelets ${bracelets.id}`}
              />
              <div className="bracelets-details">
                <p
                  className="title"
                  style={{
                    fontFamily: language === "ar" && "El Messiri",
                    letterSpacing: language === "ar" && "0",
                  }}
                >
                  {bracelets.title}
                </p>
                <p
                  style={{ fontFamily: language === "ar" && "El Messiri" }}
                  className="price"
                >
                  {bracelets.price} $
                </p>
                {/* <p className="description">{bracelets.description}</p> */}
              </div>
            </div>
          ))}
        </div>
      )}
      <BraceletsMoreBtn idCaT={parseInt(valueSelect)} language={language} />
    </Element>
  );
};
