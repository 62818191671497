import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import './Cart.scss';
import { imageURL } from '../../redux/Api';
import { DeleteBtn } from './deleteBtn/DeleteBtn';
import { removeItemFromCart, updateItemQuantity, addItemToCart } from '../../redux/slicers/cartSlice';

const Cart = ({ isOpen, onClose , language }) => {
  const dispatch = useDispatch();
  const [cartItems, setCartItems] = useState([]);
  const reduxCartItems = useSelector(state => state.cart.items);

  const messages = {
    en: 'No products to show',
    ar: 'لا يوجد منتجات',
  };

  // Sync cartItems with localStorage and Redux state
  useEffect(() => {
    const storedCartItems = JSON.parse(localStorage.getItem('cartItems')) || [];

    // Ensure each item has a default quantity of 1
    const normalizedCartItems = storedCartItems.map(item => ({
      ...item,
      quantity: item.quantity || 1
    }));
    
    setCartItems(normalizedCartItems);

    // Sync with Redux state if necessary
    if (JSON.stringify(normalizedCartItems) !== JSON.stringify(reduxCartItems)) {
      const normalizedReduxCartItems = reduxCartItems.map(item => ({
        ...item,
        quantity: item.quantity || 1
      }));
      localStorage.setItem('cartItems', JSON.stringify(normalizedReduxCartItems));
      setCartItems(normalizedReduxCartItems);
    }
  }, [reduxCartItems]);

  // Scroll to top when the component mounts
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // Handle delete product
  const handleDeleteProduct = (index) => {
    const updatedCartItems = cartItems.filter((_, i) => i !== index);
    setCartItems(updatedCartItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    dispatch(removeItemFromCart(index));
  };

  // Handle quantity change
  const handleQuantityChange = (index, increment) => {
    const updatedCartItems = cartItems.map((item, i) => {
      if (i === index) {
        const newQuantity = Math.max(1, item.quantity + increment);
        return { ...item, quantity: newQuantity };
      }
      return item;
    });

    setCartItems(updatedCartItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    dispatch(updateItemQuantity({ index, quantity: updatedCartItems[index].quantity }));
  };

  // Add item with default quantity 1
  const addItemWithDefaultQuantity = (item) => {
    const itemWithDefaultQuantity = { ...item, quantity: 1 };
    const updatedCartItems = [...cartItems, itemWithDefaultQuantity];
    setCartItems(updatedCartItems);
    localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
    dispatch(addItemToCart(itemWithDefaultQuantity));
  };

  return (
    <div className={`cart-drawer ${isOpen ? 'open' : ''}`}>
      <div className='cart-header'>
        <div><img src={require('../../assets/image/LogoAlaaEdited.png')} style={{ width: 150, height: 50 }} alt="Logo" /></div>
        <button onClick={onClose} className='close-button'>×</button>
      </div>
      <div className='cart-body'>
        <ul>
          {cartItems.length === 0 && <li className='no-items-message'>{messages[language] || messages.en} {/* Default to English if language is not defined */}</li>}
          {cartItems.map((item, index) => (
            <li key={index} className='cart-item'>
              <div className='cart-images'>
                <img src={`${imageURL}${item.file.path}`} alt="Item" />
              </div>
              <div className='cart-details'>
                <div className='cart-details-title'>
                  <h2>{item.title}</h2>
                  <p>{`${item.price}$`}</p>
                </div>
                <p className='cart-details-description'>{item.description}</p>
                <div className='cart-quantity-controls'>
                  <button className='cart-quantity-less-control' onClick={() => handleQuantityChange(index, -1)}>-</button>
                  <span>{item.quantity}</span>
                  <button className='cart-quantity-plus-control' onClick={() => handleQuantityChange(index, 1)}>+</button>
                </div>
              </div>
              <div className='cart-delete-btn-container'>
                <DeleteBtn onDelete={() => handleDeleteProduct(index)} />
              </div>
            </li>
          ))}
        </ul>
        <div className='cart-footer'>
          <div className='checkout-section'>
            <NavLink className='checkout-button' onClick={onClose} to='/checkout'>Proceed to Checkout</NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
